import React from "react";
import { Helmet } from "react-helmet";
import { centeredBlock, legalTextStyle } from "../style/shared";
import { css } from "@emotion/react";
import MailLink from "../components/MailLink";

const additionalSpacing = css`
    padding-top: 12px;
    padding-bottom: 84px;
`;

export default () => (
    <React.Fragment>
        <Helmet>
            <title>Oliver Schmitt - Impressum</title>
            <meta name="robots" content="noindex nofollow" />
        </Helmet>
        <div css={[centeredBlock, additionalSpacing, legalTextStyle]}>
            <h1>Impressum</h1>
            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>
                Oliver Schmitt Steuerberatungesellschaft mbH
                <br />
                Kasseler Stra&szlig;e 30
                <br />
                61118 Bad Vilbel
            </p>
            <p>
                Handelsregister: HRB 106587
                <br />
                Registergericht: Amtsgericht Frankfurt am Main
            </p>
            <p>
                <strong>Vertreten durch die Gesch&auml;ftsf&uuml;hrer:</strong>
                <br />
                Herrn Oliver Schmitt, Steuerberater
            </p>
            <h2>Kontakt</h2>
            <p>
                Telefon: +49 (6101) 800660
                <br />
                Telefax: +49 (6101) 800670
                <br />
                E-Mail: <MailLink to="info" />
            </p>
            <h2>Umsatzsteuer-ID</h2>
            <p>
                Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27 a
                Umsatzsteuergesetz:
                <br />
                DE 308 627 799
            </p>
            <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
            <p>
                Die gesetzliche Berufsbezeichnung Steuerberater wurde in der
                Bundesrepublik Deutschland verliehen.
                <br />
                Zust&auml;ndige Kammer: Steuerberaterkammer Hessen,
                Bleichstra&szlig;e 1, Frankfurt am Main
            </p>
            <p>Es gelten die folgenden berufsrechtlichen Regelungen:</p>
            <ul>
                <li>Steuerberatungsgesetz (StBerG)</li>
                <li>
                    Durchf&uuml;hrungsverordnung zum Steuerberatungsgesetz
                    (DVStB)
                </li>
                <li>Steuerberaterverg&uuml;tungsverordnung (StBVV)</li>
                <li>Berufsordnung der Steuerberater</li>
            </ul>
            <p>
                Die Regelungen sind einsehbar unter:{" "}
                <a
                    href="https://www.bstbk.de/de/steuerberater/berufsrecht/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://www.bstbk.de/de/steuerberater/berufsrecht/
                </a>
            </p>
            <h2>Angaben zur Berufshaftpflichtversicherung</h2>
            <p>
                <strong>Name und Sitz des Versicherers:</strong>
                <br />
                Allianz Deutschland AG
                <br />
                K&ouml;niginstraße 28
                <br />
                80802 M&uuml;nchen
            </p>
            <p>
                <strong>Geltungsraum der Versicherung:</strong>
                <br />
                Deutschland
            </p>
            <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <h3>Haftung f&uuml;r Inhalte</h3>
            <p>
                Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
                f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
                Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir
                als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte
                oder gespeicherte fremde Informationen zu &uuml;berwachen oder
                nach Umst&auml;nden zu forschen, die auf eine rechtswidrige
                T&auml;tigkeit hinweisen.
            </p>
            <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                m&ouml;glich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <h3>Haftung f&uuml;r Links</h3>
            <p>
                Unser Angebot enth&auml;lt Links zu externen Websites Dritter,
                auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen
                wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
                &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
                stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
                &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt
                der Verlinkung nicht erkennbar.
            </p>
            <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
            </p>
            <h3>Urheberrecht</h3>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
                bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors
                bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
            <p>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.
            </p>
            <p>
                Das auf dieser Seite verwendete Bildmaterial ist entwender
                selbst erstellt oder stammt von{" "}
                <a
                    href="https://unsplash.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    unsplash.com
                </a>
                . Die verwenden SVG-Symbole stammen von{" "}
                <a
                    href="https://fontawesome.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    fontawesome.com
                </a>
                .
            </p>
            <p>
                Quelle:{" "}
                <a
                    href="https://www.e-recht24.de"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    e-recht24.de
                </a>
            </p>
        </div>
    </React.Fragment>
);
